export default [
  {
    key: 'gameId',
    label: 'field.game_id',
    type: 'text',
    cols: 6,
  },
  {
    key: 'serverId',
    label: 'field.serverId',
    type: 'text',
    cols: 6,
  },
  {
    key: 'invoiceId',
    label: 'field.id',
    type: 'text',
    cols: 4,
  },
  {
    key: 'tran_id',
    label: 'field.abaTransaction',
    type: 'text',
    cols: 4,
  },
  {
    key: 'packageId',
    label: 'field.package',
    type: 'nAsynSingleSelection',
    repository: 'package',
    selectionKey: 'id',
    selectionLabel: 'displayText',
    cols: 4,
  },
  {
    key: 'wholeSaleId',
    label: 'field.wholeSale',
    type: 'nAsynSingleSelection',
    repository: 'wholeSale',
    selectionKey: 'id',
    selectionLabel: 'userName',
    cols: 4,
  },
  {
    key: 'startDateAt',
    label: 'field.startDate',
    type: 'date',
    cols: 3,
  },
  {
    key: 'endDateAt',
    label: 'field.endDate',
    type: 'date',
    cols: 3,
  },
  {
    key: 'sortPayment',
    label: 'field.sortPay',
    type: 'singleSelect',
    cols: 3,
    options: [
      {
        name: 'field.mostPay',
        id: 'desc',
      },
      {
        name: 'field.leastPay',
        id: 'asc',
      },
    ],
  },
  {
    key: 'sortType',
    label: 'field.type',
    type: 'singleSelect',
    cols: 3,
    options: [
      {
        name: 'field.manual',
        id: 'manual',
      },
      {
        name: 'field.instant',
        id: 'instant',
      },
      {
        name: 'field.preOrder',
        id: 'pre_order',
      },
      {
        name: 'admin Pre Order',
        id: 'admin_pre_order',
      },
    ],
  },
  {
    key: 'paymentSuccess',
    label: 'Payment Status',
    type: 'singleSelect',
    cols: 3,
    options: [
      {
        name: 'Success',
        id: true,
      },
      {
        name: 'Fail',
        id: false,
      },
    ],
  },
]
